'use client'
import { useCallback, useRef, useState } from 'react'
import { Modal } from '@/components/modal'
import useAmplitude from '@/hooks/useAmplitude'
import { cls, setLocalStorage } from '@/utils'
import Dialog from '@/components/dialog'
import { DialogCloseButtonClassName } from '../ui/dialog'
import IconExit from '@haiper/icons-svg/icons/outline/logout.svg'
import Button from '@/components/button'
import PersonaSourceForm from './_source'
import PersonaExperiencedVideoToolsForm from './_experienced_video_tools'
import { Persona } from '@/types'
import { useAtom } from 'jotai'
import { personaAtom } from '@/atoms'
import { postUserSurvey } from '@/service/persona.service'
import PersonaPurposeForm from './_purpose'
import PersonaProfileForm from './_profile'
import useSurvey from '@/hooks/useSurvery'
import useOnboarding from '@/hooks/useOnboarding'

const steps = ['source', 'purpose', 'experienced_video_tools', 'end']

const stepComponentMap = {
  source: PersonaSourceForm,
  purpose: PersonaPurposeForm,
  experienced_video_tools: PersonaExperiencedVideoToolsForm,
  end: PersonaProfileForm,
}

export default function PersonaDialog() {
  const [persona, setPersona] = useAtom(personaAtom)
  const { showSurvey, closeSurvey, surveyClosing, dialogClassName } = useSurvey()
  useOnboarding()

  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)

  const { track } = useAmplitude()

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (!open) {
        track('click:persona:try-close')
        setConfirmDialogVisible(true)
      }
    },
    [track],
  )

  const handleExit = useCallback(
    (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()
      track('click:persona:exit')
      setConfirmDialogVisible(false)
      closeSurvey()
    },
    [track, closeSurvey],
  )

  const handleStay = useCallback(
    (e: any) => {
      e?.preventDefault()
      e?.stopPropagation()
      track('click:persona:stay')
      setConfirmDialogVisible(false)
    },
    [track],
  )

  const handleConfirmDialogOpenChange = useCallback((open: boolean) => {
    setConfirmDialogVisible(open)
  }, [])

  const currentStep = persona?.step && steps.includes(persona.step) ? persona.step : steps[0]

  const apiPromiseRef = useRef<Promise<any> | null>(null)

  const handleGoNext = useCallback(
    async (values: Partial<Persona>) => {
      if (currentStep === 'end') {
        closeSurvey()
        await apiPromiseRef.current
        return
      }
      let nextStepIndex = steps.indexOf(currentStep) + 1
      const nextStep = steps[nextStepIndex]

      setPersona((prev) => {
        const newValue = {
          ...prev,
          ...values,
          survey_version: '2024-10-09',
          step: nextStep as any,
        }
        if (nextStep === 'end') {
          apiPromiseRef.current = postUserSurvey(newValue)
        }
        setLocalStorage('persona', newValue)
        return newValue
      })
    },
    [currentStep, setPersona, closeSurvey],
  )

  const handleGoBack = useCallback(() => {
    let prevStepIndex = steps.indexOf(currentStep) - 1
    if (prevStepIndex >= 0) {
      const prevStep = steps[prevStepIndex]
      setPersona((prev) => ({
        ...prev,
        step: prevStep as any,
      }))
    }
  }, [currentStep, setPersona])

  const renderSteps = () => {
    const StepComponent = stepComponentMap[currentStep as keyof typeof stepComponentMap]
    if (StepComponent) {
      return (
        <StepComponent
          index={steps.indexOf(currentStep)}
          total={steps.length - 1} // exclude end step
          value={persona}
          onSubmit={handleGoNext}
          onBack={handleGoBack}
        />
      )
    }
    return null
  }

  if (!showSurvey) {
    return null
  }

  return (
    <>
      <Modal
        withBroadcasts
        id='persona-survey'
        open={showSurvey}
        className={cls(
          'pt-[48px] md:pt-10 shadow-none max-w-[100vw] md:max-w-[100vw] rounded-none sm:rounded-none',
          // surveyClosing && 'max-w-11 max-h-11 md:max-w-12 md:max-h-12 p-0 md:p-0 min-w-0 md:min-w-0 min-h-0 md:min-h-0 opacity-20 aspect-square m-0',
          dialogClassName,
        )}
        closeClassName={surveyClosing ? 'hidden' : ''}
        overlayClassName={cls(surveyClosing ? 'bg-transparent border-0 border-none backdrop-blur-none' : '')}
        onOpenChange={handleOpenChange}
      >
        <div key={currentStep} className='size-full'>
          {renderSteps()}
        </div>
        <Dialog
          open={confirmDialogVisible}
          title={
            <div className='flex flex-col gap-3'>
              <div aria-label='icon' className='size-12 rounded-full bg-surface-active p-3'>
                <IconExit className='size-full text-icon-interactive ' />
              </div>
              <div className='w-full flex flex-col gap-1'>
                <div className='text-heading-lg font-bold'>Exit?</div>
                <div className='text-text-subdued text-body-md font-normal'>
                  You will lose your progress if you exit now
                </div>
              </div>
            </div>
          }
          titleClassName='mb-6'
          className={cls('gap-0 md:w-[343px] p-4')}
          footerClassName='mt-[30px]'
          footer={null}
          onOpenAutoFocus={(e) => {
            e?.preventDefault()
            if (!e.target) return
            const elSelf = e.target as HTMLElement
            const elClose = elSelf.querySelector(`.${DialogCloseButtonClassName}`) as HTMLButtonElement
            elClose?.focus?.()
          }}
          onOpenChange={handleConfirmDialogOpenChange}
        >
          <div className='flex flex-col gap-3'>
            <Button variant='primary' className='w-full' onClick={handleStay}>
              <span className='text-text-on-color'>Stay and continue</span>
            </Button>
            <Button variant='outline' className='w-full' onClick={handleExit}>
              <span className='text-text-critical'>Exit</span>
            </Button>
          </div>
        </Dialog>
      </Modal>
    </>
  )
}
