import Button from '@/components/button'
import { PersonaFormProps } from './_common'
import IconMovies from '@/public/assets/persona/movies.svg'
import Back from './_back'
import { useCallback, useEffect } from 'react'
import useAmplitude from '@/hooks/useAmplitude'
import { cls } from '@/utils'

const options = [
  { label: 'Novice', value: 'novice' },
  { label: 'Intermediate', value: 'intermediate' },
  { label: 'Expert', value: 'expert' },
]

export default function PersonaExperiencedVideoToolsForm({ value, onSubmit, onBack, index, total }: PersonaFormProps) {
  const handleChange = useCallback(
    (value: string) => {
      onSubmit({ experienced_video_tools: value })
    },
    [onSubmit],
  )

  const { track } = useAmplitude()
  useEffect(() => {
    track('view:persona:experienced-video-tools')
  }, [track])

  const buttonStyle =
    'p-3 px-2 flex items-center flex-1 w-full justify-start rounded-lg border-2 border-b-4 border-solid border-border hover:border-border-hover shadow-sm'
  const activeColor = 'bg-surface-active hover:bg-surface-active border-border-active hover:border-border-active'

  return (
    <div className='size-full flex items-center justify-center'>
      <div className='md:w-[480px] h-[640px]'>
        <div className='w-full flex flex-col gap-2 p-6'>
          <span className='text-body-lg font-normal tracking-32 text-text-subdued'>
            Question {index + 1} / {total}
          </span>
          <div className='w-full text-heading-lg font-bold tracking-45' aria-label='title'>
            Experienced with video tools?
          </div>
        </div>
        <div className='w-full p-6 pt-0 flex gap-5 h-[104px] items-center'>
          <div className='size-[75px] p-[2.5px]'>
            <IconMovies className='size-[75px]' />
          </div>
          <div className='text-heading-md font-bold'>
            Such as:
            <br />
            Adobe Premiere Pro, Final Cut Pro, After Effects, CapCut, DaVinci Resolve, iMovie, etc.
          </div>
        </div>
        <div className='px-6 gap-4 w-full flex flex-col'>
          {options.map((option) => {
            return (
              <Button
                key={option.value}
                className={cls(buttonStyle, value?.experienced_video_tools === option.value ? activeColor : '')}
                onClick={() => handleChange(option.value)}
              >
                <div className='flex w-full justify-start items-center gap-4'>
                  <span className='text-text text-heading-md font-bold'>{option.label}</span>
                </div>
              </Button>
            )
          })}
        </div>
        <div className='flex justify-between w-full items-center p-6 pt-4'>
          <Back className='' onBack={onBack} />
          <div />
        </div>
      </div>
    </div>
  )
}
