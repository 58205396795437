import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import Dialog, { DialogClose } from '@/components/dialog'
import IconClose from '@haiper/icons-svg/icons/outline/crossed-large.svg'
import { activeTemplateIdAtom, auth0SignInAtom, creationInputAtom, readedNewFeatureKeysAtom } from '@/atoms'
import { cls, getLocalStorage, setLocalStorage, whisper } from '@/utils'
import { useCallback, useEffect, useRef } from 'react'
import { CACHE_KEY_NEW_FEATURE_DIALOG, NEW_FEATURE_KISSING_TEMPLATE } from '@/constants'
import useAmplitude from '@/hooks/useAmplitude'
import { uniq } from 'lodash-es'
import { usePathname } from 'next/navigation'
import Video, { VideoApi } from '@/components/video'
import Button from '@/components/button'
import Link from '@/components/link'
import { useBreakpoint } from '@/hooks/useBreakPoint'

const pcVideoUrl = 'https://scontent.haiper.ai/webapp/videos/introduce/kissing-template.mp4'
const pcThumbnailUrl = 'https://scontent.haiper.ai/webapp/videos/introduce/kissing-template.webp'

const mobileVideoUrl = 'https://scontent.haiper.ai/webapp/videos/introduce/kissing-template-mobile.mp4'
const mobileThumbnailUrl = 'https://scontent.haiper.ai/webapp/videos/introduce/kissing-template-mobile.webp'

// const kissTemplateId = '6707b71034110080fb021914'

export const kissingTemplateDialogVisibleAtom = atom(
  !getLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG)?.includes(NEW_FEATURE_KISSING_TEMPLATE),
)

export default function KissingTemplateNewFeatureDialog() {
  const [readedNewFeatureKeys, setReadedNewFeatureKeys] = useAtom(readedNewFeatureKeysAtom)
  const isLogin = useAtomValue(auth0SignInAtom)
  const { track } = useAmplitude()
  const { isBelowMd } = useBreakpoint('md')

  const [kissingTemplateDialogVisible, setkissingTemplateDialogVisible] = useAtom(kissingTemplateDialogVisibleAtom)

  const videoApiRef = useRef<VideoApi | null>(null)

  const read = useCallback(() => {
    const newKeys = uniq([...readedNewFeatureKeys, NEW_FEATURE_KISSING_TEMPLATE])
    setReadedNewFeatureKeys(newKeys)
    setLocalStorage(CACHE_KEY_NEW_FEATURE_DIALOG, newKeys)
    setkissingTemplateDialogVisible(false)
  }, [readedNewFeatureKeys, setReadedNewFeatureKeys, setkissingTemplateDialogVisible])

  const handleClose = useCallback(() => {
    read()
  }, [read])

  const viewEventTrackedRef = useRef(false)

  const pathname = usePathname()

  const realOpen =
    kissingTemplateDialogVisible && isLogin && !pathname.startsWith('/auth') && !pathname.startsWith('/onboarding')

  useEffect(() => {
    if (realOpen && !viewEventTrackedRef.current) {
      track('view:new-feature:kissing-template')
      viewEventTrackedRef.current = true
    }
  }, [realOpen, track])

  // const setCreationInput = useSetAtom(creationInputAtom)
  // const setActiveTemplateId = useSetAtom(activeTemplateIdAtom)

  const startCreating = useCallback(() => {
    track('click:new-feature:kissing-template:start-creating')

    // setCreationInput((prev) => ({
    //   ...prev,
    //   creation: undefined,
    //   mode: undefined,
    //   expanded: true,
    //   focusing: true,
    // }))
    // setActiveTemplateId(kissTemplateId)
    read()
  }, [track, read])

  const onCloseButtonClick = useCallback(() => {
    read()
    track('click:new-feature:kissing-template:close')
  }, [track, read])

  const autoTurnedOnSoundRef = useRef(false)

  const tryTurnOnSound = useCallback(() => {
    if (videoApiRef.current && !autoTurnedOnSoundRef.current) {
      const api = videoApiRef.current
      const video = videoApiRef.current?.video

      if (video && !video?.paused) {
        api?.unmute()
        autoTurnedOnSoundRef.current = true
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('click', tryTurnOnSound)

    return () => {
      window.removeEventListener('click', tryTurnOnSound)
    }
  }, [tryTurnOnSound])

  return (
    <Dialog
      open={realOpen}
      titleClassName='hidden'
      // className='absolute md:relative inset-0 md:inset-none md:w-[578px] md:max-w-none p-4 md:p-6 overflow-x-hidden'
      className={cls(
        'relative gap-0 w-full md:w-[587px] max-w-full md:h-[460px] p-0 border-none border-0 translate-y-0 max-h-[100vh-100px] overflow-y-hidden',
      )}
      overlayClassName='pb-0'
      closeClassName='hidden'
      footerClassName='mt-[30px]'
      footer={null}
      onOpenChange={handleClose}
    >
      <div className='w-full h-full flex flex-col md:flex-row border p-4 md:p-6 md:rounded-lg'>
        {!isBelowMd && (
          <div className='w-[259px] max-h-full hidden md:block absolute inset-y-0 left-0 overflow-hidden'>
            <Video
              muted
              autoPlay
              loop
              controls
              hasAudioTrack
              preload='auto'
              className='cursor-pointer rounded-l-md rounded-r-none border-0 border-none h-full min-h-full'
              roundedClassName='rounded-l-md rounded-r-none rounded-r-0'
              src={pcVideoUrl}
              poster={pcThumbnailUrl}
              api={videoApiRef}
            />
          </div>
        )}
        <div className='w-full h-full flex flex-col justify-between md:pl-[259px] gap-4'>
          <div className='w-full '>
            <div className='flex flex-col gap-6 pb-0'>
              {isBelowMd && (
                <Video
                  muted
                  autoPlay
                  loop
                  controls
                  hasAudioTrack
                  preload='auto'
                  className='cursor-pointer rounded-md border border-border aspect-video w-full md:hidden'
                  roundedClassName='rounded-none'
                  src={mobileVideoUrl}
                  poster={mobileThumbnailUrl}
                  api={videoApiRef}
                />
              )}
              <div className='flex justify-between gap-4 items-center mb-6'>
                <div className='flex items-center gap-1 text-text font-bold text-heading-2xl h-14 tracking-32'>
                  💋 Join the Haiper Kissing Trend
                </div>
                <DialogClose asChild autoFocus={false}>
                  <Button autoFocus={false} variant='transparent' onClick={onCloseButtonClick}>
                    <IconClose className='size-6 text-icon z-10' />
                  </Button>
                </DialogClose>
              </div>
            </div>
            <div className='w-full text-text flex flex-col text-body-md tracking-15 [&_strong]:text-heading-md [&_strong]:tracking-32 [&_strong]:font-bold'>
              {/* <Markdown>
                {markdownContent}
              </Markdown> */}
              <span>
                Create heartwarming videos using our Kissing Template and post them on X, TikTok, or Instagram!
              </span>
              <br />
              <span>
                💖 <strong>15</strong> winners will receive <strong>3,000</strong> credits on Haiper.ai 🌟
              </span>
              <br />
              <span>
                Just post your video, tag us (<strong>@HaiperGenAI</strong> on X, <strong>@Haiper.ai</strong> on TikTok
                & Instagram), and you could win!
              </span>
              <br />
              <span>Get started now—make your moment unforgettable! 🎬</span>
            </div>
          </div>
          <div className='w-full h-10 '>
            <Button variant='primary' className='w-full rounded-md shadow-sm' onClick={startCreating}>
              <Link prefetch href='/template/6707b71034110080fb021914' className='w-full'>
                Start creating
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
