'use client'

import { atom, useAtom } from 'jotai'
import { useCachedMyProfile } from './useMyProfile'
import { useCallback, useMemo } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { useBreakpoint } from './useBreakPoint'
import { cls } from '@/utils'

export const showSurveyAtom = atom<boolean>(false)
export const surveyClosingAtom = atom<boolean>(false)

export default function useSurvey() {
  const [showSurvey, setShowSurvey] = useAtom(showSurveyAtom)
  const [surveyClosing, setSurveyClosing] = useAtom(surveyClosingAtom)
  const { isBelowMd } = useBreakpoint('md')

  const pathname = usePathname()
  const router = useRouter()

  const dialogClassName = useMemo(
    () =>
      cls(
        surveyClosing
          ? '-translate-x-1/2 md:translate-x-full translate-y-full'
          : '-translate-x-1/2 -translate-y-1/2 scale-100',
      ),
    [surveyClosing],
  )

  // const { data: switches, isValidating: switchesLoading } = useCachedSwitches()
  const { data: profile } = useCachedMyProfile()

  const isPlayground = pathname === '/playground/survey'

  const visible = (isPlayground || (profile && !profile?.survey_completed)) && showSurvey

  const openSurvey = useCallback(() => {
    setShowSurvey(true)
  }, [setShowSurvey])

  const closeSurvey = useCallback(() => {
    if (surveyClosing) {
      return
    }
    setSurveyClosing(true)
    // setShowSurvey(false)
    const dialog: HTMLDivElement | null = document.querySelector('#persona-survey')
    const navLinkCreate: HTMLAnchorElement | null = document.querySelector('#nav-link-create')

    // move survey dialog to the position of create button, with animation
    if (dialog && navLinkCreate) {
      const dialogRect = dialog.getBoundingClientRect()
      const buttonRect = navLinkCreate.getBoundingClientRect()

      const scaleX = buttonRect.width / dialogRect.width
      const scaleY = buttonRect.height / dialogRect.height

      const transformStart = ['translateX(-50%)', 'translateY(-50%)', 'scaleX(1)', 'scaleY(1)'].join(' ')

      const transformEnd = [
        isBelowMd ? 'translateX(-50%)' : 'translateX(-100%)',
        isBelowMd ? 'translateY(100%)' : `translateY(calc(${buttonRect.top}px - 100%))`,
        `scaleX(${scaleX})`,
        `scaleY(${scaleY})`,
      ].join(' ')

      const keyFrames = [{ transform: transformStart }, { transform: transformEnd }]

      dialog.animate(keyFrames, {
        duration: 400,
        easing: 'ease-in',
        iterations: 1,
      })
    }

    setTimeout(() => {
      setShowSurvey(false)
      setSurveyClosing(false)
      router.push('/')
    }, 300)
  }, [setShowSurvey, setSurveyClosing, router, isBelowMd, surveyClosing])

  return {
    dialogClassName,
    showSurvey: visible,
    openSurvey,
    closeSurvey,
    surveyClosing,
  }
}
